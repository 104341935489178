import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { Container, Col, Row/* , Card */ } from "react-bootstrap"

import Img from "gatsby-image"

/* import MyStyles from "./cda.module.css" */

import "./cda.css"

const CdaContent = () => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/cda/" } }
          sort: { fields: frontmatter___val, order: ASC }
        ) {
          edges {
            node {
              excerpt(format: MARKDOWN)
              html
              frontmatter {
                date
                path
                title
              }
            }
          }
        }
        placeholderImage: file(
          relativePath: { eq: "cda/struttura-archimede.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Container
          className="h-100"
          style={{
            paddingTop: `2rem`,
            paddingBottom: `2rem`,
          }}
        >
          <Row>
            <Col md={1}></Col>
            <Col>
              <div>
                {/* <h3 className="title1">
                  CDA
                </h3> */}
                <div className="display-4 text-center">CDA</div>
              </div>
            </Col>
            <Col md={1}></Col>
          </Row>
          <Row>
            <Col md={1}></Col>
            <Col>
              <div>
                <p className="paragraph2">
                  Archimede è composta da un gruppo di manager provenienti dal
                  settore dell’industria e dei servizi, con esperienze
                  lavorative ventennali trascorse in imprese multinazionali.
                  Grazie a queste competenze maturate in diversi ambiti
                  dell’economia e ad una preparazione multitasking, il
                  management persegue l’obiettivo di rafforzarsi sul mercato
                  delle energie rinnovabili e dei servizi alle imprese di tipo
                  B2B. L’attuale composizione del CDA di Archimede è la
                  seguente:
                </p>
              </div>
            </Col>
            <Col md={1}></Col>
          </Row>

          <Row>
            <Col md={3}></Col>
            <Col className="text-center">
              <Img fluid={data.placeholderImage.childImageSharp.fluid} alt="" />
            </Col>
            <Col md={3}></Col>
          </Row>

          {data.allMarkdownRemark.edges.map(({ node }, index) => (
            
              <div key={index} >
                <Row className="p-3">
                  <Col></Col>
                  <Col>
                    <hr />
                  </Col>
                  <Col></Col>
                </Row>

                <Row>
                  <Col md={1}></Col>
                  <Col>
                    <div>
                      <h3>{node.frontmatter.title}</h3>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: node.html }}></div>
                  </Col>
                  <Col md={1}></Col>
                </Row>
              </div>
            
          ))}

          {/* <div class="col-md-12 text-right">
							<img class="img-fluid w-50 mt-2" src="assets/images/loghi/firma.png" alt="">
						</div> */}
        </Container>
      </>
    )}
  />
)
export default CdaContent
